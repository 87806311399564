import { Box, Button, Link as MaterialLink, Paper, Typography } from '@mui/material';
import React, { useEffect } from 'react';

export const Download = () => {
    useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<Paper
			sx={{
				margin: '90px auto 20px',
				width: {xs: '98%', sm:'80%'},
				height: '55vh',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				rowGap: '20px',
			}}>
			<Typography variant='h4' sx={{ textTransform: 'uppercase', textAlign: 'center' }}>
				Pobierz pliki
			</Typography>
			<Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', border: '1px solid black', padding: '15px', borderRadius: '15px'}}>
				<Typography sx={{textAlign: 'center'}} >Wzór wniosku o przyjęcie do klubu:</Typography>
				<MaterialLink
					href='/deklaracja.klubowa.odt'
					download
					className='download-button'>
					<Button>Kliknij by pobrać</Button>
				</MaterialLink>
			</Box>
		</Paper>
	);
};
