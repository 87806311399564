import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { BrowserRouter } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: 'AIzaSyCl5Pc5mE9yJGarP3uy3TIUGeFJZIsn_go',
	authDomain: 'polonia-bialogon.firebaseapp.com',
	projectId: 'polonia-bialogon',
	storageBucket: 'polonia-bialogon.appspot.com',
	messagingSenderId: '336439879330',
	appId: '1:336439879330:web:1ea9330c467a73d34e19fb',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</React.StrictMode>
);
