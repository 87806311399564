/* eslint-disable jsx-a11y/img-redundant-alt */
import { FullscreenExit } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import podatekVertical from './podatek_polonia1.jpg';
import podatekHorizontal from './podatek_polonia2.jpg';

export const SupportClub = () => {
	const screenWidth = window.innerWidth;

	// Determine the image source based on screen width
	const imageSrc = screenWidth >= 600 ? podatekHorizontal : podatekVertical;
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<Box
			sx={{
				width: { xs: '95%', sm: '85%' },
				height: 500,
				margin: '90px auto 10px',
				pt: 18,
				backgroundImage: `url(${imageSrc})`,
				backgroundPosition: 'center',
				backgroundSize: 'contain',
				backgroundRepeat: 'no-repeat',
			}}>
			<Typography
				sx={{
					lineHeight: '1.2',
					fontSize: { xs: '30px', sm: '60px', md: '80px' },
					
					textAlign: 'center',
					fontFamily: 'Hind Siliguri, sans-serif',
					fontWeight: '700',
					color: '#2d4051',
				}}>
				WESPRZYJ KLUB
			</Typography>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					flexDirection: { xs: 'column', sm: 'row' },
					mb:{xs: 2, sm: 7}
				}}>
				<Typography
					sx={{
				
						fontSize: { xs: '30px', sm: '40px', md: '50px' },
						textAlign: 'center',
						fontFamily: 'Hind Siliguri, sans-serif',
						fontWeight: '700',
						color: '#2d4051',
					}}>
					PRZEKAŻ
				</Typography>
				<Typography
					sx={{
						margin: '0 10px 7px 10px',
						fontSize: { xs: '30px', sm: '40px', md: '50px' },
						textAlign: 'center',
						whiteSpace: 'nowrap',
						fontFamily: 'Hind Siliguri, sans-serif',
						fontWeight: '700',
						color: '#2d4051',
					}}>
					1,5% PODATKU
				</Typography>{' '}
			</Box>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					flexDirection: { xs: 'column', sm: 'row' },
				}}>
				<Typography
					sx={{
						m: 1,
						fontSize: { xs: 15, sm: 17 },
						textAlign: 'center',
						whiteSpace: 'nowrap',
						fontFamily: 'Public Sans, sans-serif',
						color: '#2d4051',
					}}>
					Cel szczegółowy:
				</Typography>
				<Typography
					sx={{
						m: 1,
						fontSize: { xs: 15, sm: 17 },
						textAlign: 'center',
						whiteSpace: 'nowrap',
						fontFamily: 'Public Sans, sans-serif',
						color: '#2d4051',
					}}>
					POLONIA BIAŁOGON 546
				</Typography>

				<Typography
					sx={{
						m: 1,
						fontSize: { xs: 15, sm: 17 },
						textAlign: 'center',
						whiteSpace: 'nowrap',
						fontFamily: 'Public Sans, sans-serif',
						color: '#2d4051',
					}}>
					KRS 0000 270261
				</Typography>
			</Box>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					flexDirection: { xs: 'column', sm: 'row' },
				}}>
				<Typography
					sx={{
						m: 1,
						fontSize: { xs: 15, sm: 17 },
						textAlign: 'center',
						whiteSpace: 'nowrap',
						fontFamily: 'Public Sans, sans-serif',
						color: '#2d4051',
					}}>
					PRZEKAŻ DAROWIZNĘ:
				</Typography>
				<Typography
					sx={{
						m: 1,
						fontSize: { xs: 15, sm: 17 },
						textAlign: 'center',
						fontFamily: 'Public Sans, sans-serif',
						color: '#2d4051',
					}}>
					79105014161000009032940539{' '}
				</Typography>
			</Box>
		</Box>
	);
};
